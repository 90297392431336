<template>
  <div v-if="page" class="page page-default" data-theme="dark">
    <HeaderModule v-if="page.headerModule" :module="page.headerModule" />
    <Modules :modules="page.contentModules" />
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
import { useSeo } from '~/utils/useSeo';
import HeaderModule from '~/components/header-modules/HeaderModule.vue';
import Modules from '~/components/modules/Modules.vue';
import SiteFooter from '~/components/site-footer/SiteFooter.vue';
import { useVerticalScrolling } from '~/utils/useVerticalScrolling';
import { enterDefault } from '~/page-transitions/enterDefault';
import gsap from 'gsap';
import { leaveDefault } from '~/page-transitions/leaveDefault';

useVerticalScrolling();

const { localeProperties } = useI18n();
const localePath = useLocalePath();

const { data, error } = await useAsyncGql({
  operation: 'GetPageByTranslatedSlug',
  variables: {
    slug: 'home',
    locale: localeProperties.value.siteLocale,
  },
});

const page = data.value?.defaultPage;

if (!page || error.value) {
  showError({
    statusCode: 404,
  });
}

if (page) {
  useSeo(page._seoMetaTags ?? []);
}

definePageMeta({
  middleware: 'adjust-scroll',
  scrollPosition: {
    y: 0,
    x: 0,
  },
  pageTransition: {
    onEnter: enterDefault,
    onLeave: (el, done) => {
      const router = useRouter();
      const isDelayed = router.currentRoute.value.fullPath !== '/blog';
      leaveDefault({ el, done, isDelayed });
    },
  },
});

const route = useRoute();

onMounted(function () {
  const scrollPosition = route.meta.scrollPosition as { x: number; y: number };

  if (scrollPosition.y || window.HomeScrollPosition) {
    window.scrollTo(0, scrollPosition.y || window.HomeScrollPosition);
  }
  // fix to prevent delayed load on work page
  localStorage.setItem('isReload', 'false');
});
// use Key Events to return to the top of the page
const router = useRouter();

const handleKeyUp = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    gsap.to(window, { duration: 0.5, scrollTo: 0 });
  }
  if (e.key === 'w') {
    router.push({ path: localePath('/work') });
  }
  if (e.key === 't') {
    router.push(localePath('/team'));
  }
};

onBeforeUnmount(() => {
  window.HomeScrollPosition = window.scrollY;
});

useKeyEvents({
  onKeyUp: handleKeyUp,
});
</script>

<style scoped lang="scss"></style>
